import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import {
    APP_INITIALIZER,
    ApplicationConfig,
    ErrorHandler,
    importProvidersFrom,
    provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import {
    provideRouter,
    Router,
    RouteReuseStrategy,
    withComponentInputBinding,
    withRouterConfig,
} from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
    getBrowserSupportedLanguage,
    LanguageService,
    provideLanguageService,
    SetAcceptLanguageHeaderInterceptor,
    TranslocoHttpLoader,
} from '@icp/angular/i18n';
import { DefaultIdpStorage, provideIdpServiceConfig } from '@icp/angular/idp';
import { BASE_PATH, Configuration } from '@icp/interfaces';
import { IonicRouteStrategy, isPlatform, provideIonicAngular } from '@ionic/angular/standalone';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import * as Sentry from '@sentry/angular';
import { provideLottieOptions } from 'ngx-lottie';

import { environment } from '../environments/environment';
import routes from './app-routing';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthEffects, authFeature } from './auth/store';

export const backIcon = isPlatform('ios') ? 'chevron-back-outline' : 'arrow-back-outline';

export const appConfig: ApplicationConfig = {
    providers: [
        provideExperimentalZonelessChangeDetection(),
        provideHttpClient(
            withFetch(),
            withInterceptors([SetAcceptLanguageHeaderInterceptor(authFeature.selectLanguage), AuthInterceptor]),
        ),
        provideRouter(routes, withRouterConfig({ paramsInheritanceStrategy: 'always' }), withComponentInputBinding()),
        provideIdpServiceConfig({
            serverUrl: '',
            clientId: environment.idpClientId,
            storage: DefaultIdpStorage,
        }),
        provideStore({ [authFeature.name]: authFeature.reducer }),
        provideEffects(AuthEffects),
        provideStoreDevtools({
            name: 'Cirklo Merchant',
            logOnly: environment.production,
        }),
        provideLanguageService({ selector: authFeature.selectLanguage, loader: TranslocoHttpLoader }),
        importProvidersFrom(ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })),
        provideIonicAngular({
            backButtonText: '',
            backButtonIcon: backIcon,
        }),
        provideLottieOptions({
            // animations won't render correctly if they have expressions or effects.
            // use lottie_svg if you need to render those animations
            player: () => import('lottie-web/build/player/lottie_light'),
        }),
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: true,
                dialogOptions: { lang: getBrowserSupportedLanguage() },
            }),
        },
        { provide: Sentry.TraceService, deps: [Router] },
        {
            provide: APP_INITIALIZER,
            deps: [LanguageService, Sentry.TraceService],
            multi: true,
            useFactory: (languageService: LanguageService) => () => languageService.initialize(),
        },
        { provide: BASE_PATH, useValue: '' },
        { provide: Configuration, useFactory: () => new Configuration() },
    ],
};

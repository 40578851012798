import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IdpService } from '@icp/angular/idp';
import { IonSpinner } from '@ionic/angular/standalone';

@Component({
    selector: 'cv-authorized',
    standalone: true,
    template: '<ion-spinner />',
    imports: [IonSpinner],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class AuthorizedComponent implements OnInit {
    private route = inject(ActivatedRoute);
    private idpService = inject(IdpService);
    private router = inject(Router);

    async ngOnInit() {
        const { state, code } = this.route.snapshot.queryParams;
        if (state && code) {
            try {
                await this.idpService.createIcpToken(state, code);
                await this.router.navigateByUrl('/');
                return;
            } catch (error) {
                console.error(error);
            }
        }
        await this.router.navigateByUrl('/auth/login');
    }
}

import { ChangeDetectionStrategy, Component, effect, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/browser';

import { environment } from '../environments/environment';
import { selectUserSessionUser } from './auth/store';
import { UpdateAppService } from './core/services/update-app.service';

@Component({
    selector: 'cv-root',
    templateUrl: 'app.component.html',
    styleUrl: 'app.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [IonApp, IonRouterOutlet],
})
export class AppComponent {
    private titleService = inject(Title);
    private store = inject(Store);
    private updateAppService = inject(UpdateAppService);
    private user = this.store.selectSignal(selectUserSessionUser);

    constructor() {
        this.titleService.setTitle(`Cirklo${environment.env !== 'prod' ? ` ${environment.env}` : ''}`);
        this.updateAppService.init();
        effect(() => {
            const user = this.user();
            let userScope: { id: string } | null = null;
            if (user) {
                userScope = { id: user.id };
            }
            Sentry.getCurrentScope().setUser(userScope);
        });
    }
}

import { EnvironmentInjector, inject, runInInjectionContext } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateChildFn,
    CanActivateFn,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { firstValueFrom, isObservable } from 'rxjs';

/**
 * Runs each guard in the order they are defined until one returns anything other than true.
 */
export function runGuardsSerially(
    ...guards: CanActivateFn[] | CanActivateChildFn[]
): CanActivateFn | CanActivateChildFn {
    return async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        const injector = inject(EnvironmentInjector);

        for (const guard of guards) {
            const guardResult = runInInjectionContext(injector, () => guard(route, state));
            if (guardResult instanceof UrlTree) {
                return guardResult;
            }
            // if promise
            if (guardResult instanceof Promise) {
                const resolvedValue = await guardResult;
                if (resolvedValue !== true) {
                    return resolvedValue;
                }
            } else if (isObservable(guardResult)) {
                const resolvedValue = await firstValueFrom(guardResult);
                if (resolvedValue !== true) {
                    return resolvedValue;
                }
            }
        }
        return true;
    };
}

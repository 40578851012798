import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular';
import { addIcons } from 'ionicons';
import { arrowBackOutline, chevronBackOutline } from 'ionicons/icons';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

addIcons({ arrowBackOutline, chevronBackOutline }); // for the back button
Sentry.init({
    dsn: 'https://02352b4255aa3394b8fd373619de81b6@o398318.ingest.us.sentry.io/4508080622993408',
    environment: environment.env,
    debug: false,
    enabled: environment.env !== 'local',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (environment.production) {
    enableProdMode();
}
bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));

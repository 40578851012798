import { Routes } from '@angular/router';
import { runGuardsSerially } from '@icp/angular/routing';

import { IsAuthenticatedGuard, IsNotAuthenticatedGuard, sessionOrSoftKeyGuard } from './auth/guards';
import AuthorizedComponent from './auth/pages/authorized.component';
import { LoginPage } from './auth/pages/login.page';
import { ProcessLinkComponent } from './process-link/process-link.component';

export default [
    { path: '', redirectTo: 'welcome', pathMatch: 'full' },
    {
        path: 'tabs',
        loadChildren: () => import('./tabs/tabs-routes'),
        canActivate: [runGuardsSerially(IsAuthenticatedGuard, sessionOrSoftKeyGuard)],
    },
    {
        path: 'create-business',
        canActivate: [sessionOrSoftKeyGuard],
        loadChildren: () => import('./businesses/create-business-routes'),
    },
    { path: 'welcome', canActivate: [IsNotAuthenticatedGuard], loadComponent: () => import('./welcome/welcome.page') },
    {
        path: 'store-selection',
        loadComponent: () => import('./auth/pages/store-selection/store-selection.component'),
        canActivate: [IsAuthenticatedGuard],
    },
    {
        path: 'auth',
        children: [
            { path: '', redirectTo: 'login', pathMatch: 'full' },
            { path: 'login', component: LoginPage, canActivate: [IsNotAuthenticatedGuard] },
            { path: 'authorized', component: AuthorizedComponent },
        ],
    },
    {
        path: 'link',
        children: [{ path: '**', component: ProcessLinkComponent }],
    },
] satisfies Routes;

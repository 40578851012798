import { HttpErrorResponse, HttpInterceptorFn, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { IdpService } from '@icp/angular/idp';
import { HttpHeaderNames } from '@icp/interfaces';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, throwError } from 'rxjs';

import { AuthActions } from './store';

function shouldAddAuthHeader(req: HttpRequest<unknown>): boolean {
    // Avoid sending auth headers to external services such as Google Maps */
    if (req.method === 'JSONP' || !req.url.startsWith('/')) {
        return false;
    }
    if (req.url.startsWith('/idp') || req.url.startsWith('/assets')) {
        return req.url.startsWith('/idp/v1/clients');
    }
    return true;
}

export const AuthInterceptor: HttpInterceptorFn = (req, next) => {
    if (!shouldAddAuthHeader(req)) {
        return next(req);
    }

    const idpService = inject(IdpService);
    const store = inject(Store);
    return idpService.getCurrentSession().pipe(
        catchError((err) => {
            console.log(err);
            return of(null);
        }),
        map((session) => session?.accessToken),
        switchMap((accessToken) => {
            const afterRequest = (error: unknown) => {
                if (error instanceof HttpErrorResponse) {
                    if (error.status === HttpStatusCode.Unauthorized) {
                        // Idp session expired or app Session deleted on server, log out
                        // ErrorService ignores these 401 errors so no error message will be shown.
                        store.dispatch(AuthActions.logout());
                    }
                }
                return throwError(() => error);
            };

            if (accessToken) {
                return next(
                    req.clone({
                        setHeaders: {
                            [HttpHeaderNames.AUTHORIZATION]: `Bearer ${accessToken}`,
                        },
                    }),
                ).pipe(catchError(afterRequest));
            }
            return next(req).pipe(catchError(afterRequest));
        }),
    );
};

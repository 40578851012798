import { DOCUMENT } from '@angular/common';
import { Component, inject, input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IdpService } from '@icp/angular/idp';
import { IdpOauth2AuthorizeAction, IdpOauth2LoginScope } from '@icp/interfaces';
import { IonSpinner } from '@ionic/angular/standalone';

import { environment } from '../../../environments/environment';

@Component({
    selector: 'cv-login-page',
    template: '<ion-spinner />',
    standalone: true,
    imports: [IonSpinner],
})
export class LoginPage implements OnInit {
    email = inject(ActivatedRoute).snapshot.queryParams['email'];
    action = input<IdpOauth2AuthorizeAction>();
    private document = inject(DOCUMENT);
    private idpService = inject(IdpService);

    async ngOnInit() {
        this.document.location.href = await this.idpService.authorize({
            idpUrl: environment.idpURL,
            scope: IdpOauth2LoginScope.OPENID,
            redirectUri: `${this.document.location.origin}/auth/authorized`,
            action: this.action(),
            login_hint: this.email,
        });
    }
}

import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { TranslocoService } from '@jsverse/transloco';
import { concat, delay, first, interval, of, take, tap } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ToastService } from './toast.service';

@Injectable({
    providedIn: 'root',
})
export class UpdateAppService {
    constructor(
        private appRef: ApplicationRef,
        private updates: SwUpdate,
        private toastService: ToastService,
        private translate: TranslocoService,
    ) {
        updates.versionUpdates.subscribe((event) => {
            if (event.type === 'VERSION_READY') {
                console.log('available', event);
                updates.activateUpdate().then(() => {
                    console.log('Reloading...');
                    document.location.reload();
                });
            }
        });
    }

    init() {
        if (environment.env !== 'local') {
            this.checkForUpdates();
            this.askUserToUpdate();
        }
    }

    private checkForUpdates() {
        const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable));
        const everyTenMinutes$ = interval(10 * 60 * 1000);
        const everyTenMinutesOnceAppIsStable$ = concat(appIsStable$, everyTenMinutes$);

        everyTenMinutesOnceAppIsStable$.subscribe(() => {
            if (this.updates.isEnabled) {
                this.updates.checkForUpdate();
            }
        });
    }

    private askUserToUpdate() {
        this.updates.versionUpdates.pipe(take(1)).subscribe((_) => {
            this.loadLater();

            this.toastService.toaster({
                header: this.translate.translate('NEW_VERSION'),
                mode: 'md',
                duration: 20 * 1000,
                buttons: [
                    {
                        text: this.translate.translate('LOAD_IN_TEN'),
                        role: 'cancel',
                    },
                    {
                        text: this.translate.translate('LOAD_NOW'),
                        handler: () => {
                            window.location.reload();
                        },
                    },
                ],
            });
        });
    }

    private loadLater() {
        const timeoutTenMinutes$ = of(true).pipe(
            delay(10 * 60 * 1000),
            tap(() =>
                this.toastService.toaster({
                    header: this.translate.translate('RELOAD_IN_TEN'),
                    mode: 'md',
                    duration: 5000,
                }),
            ),
            delay(10 * 1000),
            tap(() => window.location.reload()),
        );

        timeoutTenMinutes$.subscribe();
    }
}

import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IdpService } from '@icp/angular/idp';
import { IonSpinner, NavController } from '@ionic/angular/standalone';

import { ErrorService } from '../shared/error.service';

@Component({
    selector: 'cv-process-link',
    standalone: true,
    imports: [IonSpinner],
    templateUrl: './process-link.component.html',
    styleUrl: './process-link.component.scss',
})
export class ProcessLinkComponent implements OnInit {
    private router = inject(Router);
    private navController = inject(NavController);
    private idpService = inject(IdpService);
    private errorService = inject(ErrorService);

    ngOnInit() {
        this.processLink();
    }

    async processLink() {
        const link = this.router.url;
        if (link.startsWith('/link/soft-key')) {
            const softKeyId = link.split('/')[3];
            try {
                await this.idpService.signInWithSoftKey({ id: softKeyId });
            } catch (e) {
                const dialog = await this.errorService.showErrorDialog(e);
                await dialog.onDidDismiss();
            }
        }
        await this.navController.navigateRoot('/');
    }
}
